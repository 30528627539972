import LovingEggy from '../images/eggy/loving.gif';
import DancingEggy from '../images/eggy/dancing.gif';

let input = '';

function addEggyToBody(imagePath) {
  let element = document.createElement('img');
  element.src = imagePath;
  document.body.append(element);
  setTimeout(() => { window.scrollTo(0, document.body.scrollHeight) }, 500);
}

window.addEventListener('keypress', function(e) {
  if ('key' in e) { input += e.key }
  if (input.length > 5) { input = input.substr(1) }
  if (input.toLowerCase() === 'renuo') { addEggyToBody(DancingEggy) }
  if (input.toLowerCase() === 'thjnk') { addEggyToBody(LovingEggy) }
})
