// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap/dist/js/bootstrap';
import '../styles/application.scss';
import '@fortawesome/fontawesome-free/js/all';
import '../src/eggy'

import Rails from '@rails/ujs';

Rails.start();

import Turbolinks from 'turbolinks';
Turbolinks.start();

export const images = require.context('../images', true);
